import {
    IndexTable,
    useIndexResourceState,
    Text,
    Box,
    Badge,
    Tabs,
    EmptyState,
    Card,
    BlockStack,
} from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';
import React, { useCallback, useEffect, useState } from 'react';
import { getOrders } from '../api_services/orders';

export function UnsynchedOrders() {
    useEffect(() => {
        getOrders().then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    const orders = [
        {
            id: '1020',
            order: '#1020',
            date: 'Jul 20 at 4:34pm',
            customer: 'Jaydon Stanton',
            total: '$969.44',
            paymentStatus: <Badge progress="complete">Paid</Badge>,
            fulfillmentStatus: <Badge progress="incomplete">Unfulfilled</Badge>,
        },
        {
            id: '1019',
            order: '#1019',
            date: 'Jul 20 at 3:46pm',
            customer: 'Ruben Westerfelt',
            total: '$701.19',
            paymentStatus: <Badge progress="partiallyComplete">Partially paid</Badge>,
            fulfillmentStatus: <Badge progress="incomplete">Unfulfilled</Badge>,
        },
        {
            id: '1018',
            order: '#1018',
            date: 'Jul 20 at 3.44pm',
            customer: 'Leo Carder',
            total: '$798.24',
            paymentStatus: <Badge progress="complete">Paid</Badge>,
            fulfillmentStatus: <Badge progress="incomplete">Unfulfilled</Badge>,
        },
    ]
    const resourceName = {
        singular: 'order',
        plural: 'orders',
    };

    const { selectedResources, allResourcesSelected, handleSelectionChange } =
        useIndexResourceState(orders);

    const rowMarkup = orders.map(
        (
            { id, order, date, customer, total, paymentStatus, fulfillmentStatus },
            index,
        ) => (
            <IndexTable.Row
                id={id}
                key={id}
                selected={selectedResources.includes(id)}
                position={index}
            >
                <IndexTable.Cell>
                    <Text variant="bodyMd" fontWeight="bold" as="span">
                        {order}
                    </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>{date}</IndexTable.Cell>
                <IndexTable.Cell>{customer}</IndexTable.Cell>
                <IndexTable.Cell>
                    <Text as="span" alignment="end" numeric>
                        {total}
                    </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>{paymentStatus}</IndexTable.Cell>
                <IndexTable.Cell>{fulfillmentStatus}</IndexTable.Cell>
            </IndexTable.Row>
        ),
    );

    const promotedBulkActions = [
        {
            content: 'Sync orders',
            onAction: () => console.log('Todo: implement payment capture'),
        },
        {
            content: 'Delete orders',
            onAction: () => console.log('Todo: implement payment capture'),
            destructive: true,
            icon: DeleteIcon
        },
    ];

    const [selected, setSelected] = useState(0);

    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        [],
    );

    const tabs = [
        {
            id: 'all-customers-1',
            content: 'Unsynced Orders',
            accessibilityLabel: 'All Unsynced Orders',
            panelID: 'all-customers-content-1',
        },
        {
            id: 'products-1',
            content: 'Products',
            panelID: 'accepts-marketing-content-1',
        },
        {
            id: 'customer-1',
            content: 'Customers',
            panelID: 'accepts-marketing-content-1',
        }
    ];
    return (
        <>
            <BlockStack gap="200">
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} >
                </Tabs>
                <Box padding="300" >
                    {selected === 0 && (
                        <Card padding={'0'}>
                            <IndexTable
                                emptyState={<EmptyState
                                    heading="No orders to sync"
                                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                                >
                                    <p>Track and receive your incoming inventory from suppliers.</p>
                                </EmptyState>}
                                resourceName={resourceName}
                                itemCount={orders.length}
                                selectedItemsCount={
                                    allResourcesSelected ? 'All' : selectedResources.length
                                }
                                promotedBulkActions={promotedBulkActions}
                                onSelectionChange={handleSelectionChange}
                                headings={[
                                    { title: 'Order' },
                                    { title: 'Date' },
                                    { title: 'Customer' },
                                    { title: 'Total', alignment: 'end' },
                                    { title: 'Payment status' },
                                    { title: 'Fulfillment status' },
                                ]}
                                pagination={{
                                    hasNext: orders.length > 0,
                                    onNext: () => { },
                                }}
                            >
                                {rowMarkup}
                            </IndexTable>
                        </Card>
                    )}
                </Box>
            </BlockStack>
        </>
    );
}