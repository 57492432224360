import { AppProvider, Page } from '@shopify/polaris';
import React from 'react';
import { UnsynchedOrders } from './unsynced';

function AppProviderExample() {
    return (
        <AppProvider
            i18n={{
                Polaris: {
                    ResourceList: {
                        sortingLabel: 'Sort by',
                        defaultItemSingular: 'item',
                        defaultItemPlural: 'items',
                        showing: 'Showing {itemsCount} {resource}',
                        Item: {
                            viewItem: 'View details for {itemName}',
                        },
                    },
                    Common: {
                        checkbox: 'checkbox',
                    },
                },
            }}
        >
            {/* <NavMenu>
                <a href="/" rel="home">
                    Home
                </a>
                <a href="/templates">Templates</a>
                <a href="/settings">Settings</a>
            </NavMenu> */}

            <Page>
                <UnsynchedOrders />
            </Page>
        </AppProvider>
    );
}

export default AppProviderExample;